<template>
  <div class="contact container">
    <Newsletter additionalClasses="block horizontal" context="page" />
  </div>
</template>

<script>
import Newsletter from "@/components/Newsletter";

export default {
  name: "PageNewsletter",
  components: {
    Newsletter,
  },
};
</script>
